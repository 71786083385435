.form-control{
    &:focus{
        border-color: var(--#{$variable-prefix}primary-tint-40);
    }
}
.form-select{
    &:focus{
        border-color: var(--#{$variable-prefix}primary-tint-40);
    }
}

select {
    &.form-control{
        background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: 100%;
        background-position-y: 5px;
    }
}